import React from "react";
import "../styles/Newcomponent.css";

import photo1 from "../assets/BMB logo-01.png";
import photo2 from "../assets/artemis.jpeg";
import photo3 from "../assets/pashupatinath multispeciality hospital.png";
import photo4 from "../assets/iris.png";
import photo5 from "../assets/CMRI logo_2024 (1).jpg";
import photo6 from "../assets/golf view logo.png";
import photo7 from "../assets/Happy Soni Clinic Logo (1).jpg";
import photo8 from "../assets/rameshwar.jpeg";



const Slider = () => {
  const photos = [
    { src: photo1, alt: "Partner 1" },
    { src: photo2, alt: "Partner 2" },
    { src: photo3, alt: "Partner 3" },
    { src: photo4, alt: "Partner 4", },
    { src: photo5, alt: "Partner 5" },
    { src: photo6, alt: "Partner 6" },
    { src: photo7, alt: "Partner 7" },
    { src: photo8, alt: "Partner 7" },

   


  ];

   const duplicatedPhotos = [...photos, ...photos];

  return (
    <div className="slider-container">
      <div className="ajit-header">
        <p className="ajit-title">OUR ESTEEMED PARTNERS</p>
        <p className="ajit-subtitle">
          Expanding our reach by joining forces with hospitals and corporates
        </p>
      </div>

      <div className="slider-track">
        {duplicatedPhotos.map((photo, index) => (
          <div className="slider-item" key={`${photo.alt}-${index}`}>
            <img src={photo.src} alt={photo.alt} className="slider-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
